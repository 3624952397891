import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../../components/ui/Layout";
import { getImage } from "gatsby-plugin-image";
import * as classes from "./Projects.module.css";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import ButtonIconLink from "../../components/ui/ButtonIconLink";
import ButtonLink from "../../components/ui/ButtonLink";

const ProjectPage = ({ data }) => {
  const placeholderImage = getImage(data.mdx.frontmatter.image_main);
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const github_link = data.mdx.frontmatter.github_link || '';
  const demo_link = data.mdx.frontmatter.demo_link || '';

  return (
    <>
      <BackgroundImage className={classes.heroImage} Tag="section" {...bgImage} preserveStackingContext>
        <h1>{data.mdx.frontmatter.title}</h1>
      </BackgroundImage>
      
      <Layout title={data.mdx.frontmatter.title}>
        <section className={classes.content}>
          {github_link ? <ButtonIconLink href={github_link} icon="GitHub">Github</ButtonIconLink> : ''}
          {demo_link ? <ButtonIconLink href={demo_link} icon="ExternalLink">Demo</ButtonIconLink> : ''}
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
          <ButtonLink to="../">Back to Projects</ButtonLink>
        </section>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        github_link
        demo_link
        date(formatString: "MMMM D, YYYY")
        image_main {
          childImageSharp {
            gatsbyImageData(width: 2000)
          }
        }
        image_alt
        image_secondary {
          childImageSharp {
            gatsbyImageData(width: 2000)
          }
        }
        image_secondary_alt
      }
      body
    }
  }
`;

export default ProjectPage;
