import React from "react";
import * as classes from "./ButtonIconLink.module.css";
import { GitHub, ExternalLink } from "react-feather";

const ButtonIconLink = (props) => {
  const whichButton = (icon) => {
    switch (icon) {
      case "GitHub":
        return <GitHub />;
      case "ExternalLink":
        return <ExternalLink />;
      default:
        break;
    }
  };

  let chosenIcon = whichButton(props.icon);

  return <a className={classes.button} href={props.href} target="_blank" rel="noopener" rel="noreferrer">{chosenIcon} {props.children}</a>;
};

export default ButtonIconLink;
